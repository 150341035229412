<template>
  <div>
    <div>
      <h1 class="mr-sm-4 header-tablepage">THEME</h1>
    </div>
    <div class="title-panel mt-3">Theme Setting</div>

    <div class="no-gutters bg-white p-3" v-if="isLoading">
      <div>
        <UploadImageAndPreview
          :imagePath="imagePath"
          v-for="ele of listElememt"
          :textFloat="ele.title"
          :desc="ele.desc"
          :key="ele.key"
          :name="ele.key"
          v-model="form[ele.key]"
          :value="form[ele.key]"
          class="mb-3"
          @deleteImage="deleteImage"
        />
      </div>

      <div class="mb-3">
        <ColorPicker
          textFloat="Primary Color"
          v-model="form.themePrimaryColor"
          :value="form.themePrimaryColor"
          name="primary_color"
        />
      </div>
      <div class="mb-3">
        <ColorPicker
          textFloat="Secondary Color"
          v-model="form.themeSecondaryColor"
          :value="form.themeSecondaryColor"
          name="secondary_color"
        />
      </div>
      <div class="mb-3">
        <ColorPicker
          textFloat="Light Primary Color"
          v-model="form.themeLightPrimaryColor"
          :value="form.themeLightPrimaryColor"
          name="font_color"
        />
      </div>
      <div class="mb-3">
        <ColorPicker
          textFloat="Disable Color"
          v-model="form.themeDisableColor"
          :value="form.themeDisableColor"
          name="disable_color"
        />
      </div>
      <div>
        <b-form-group label="Background">
          <b-form-radio-group
            id="radio-slots"
            v-model="form.themeBackgroundPage"
            stacked
            name="radio-options-slots"
          >
            <b-form-radio name="some-radios" value="1"
              >Solid Color</b-form-radio
            >
            <b-form-radio name="some-radios" value="2"
              >Image <small></small
            ></b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <ColorPicker
          v-if="form.themeBackgroundPage == 1"
          v-model="form.themeSolidColor"
          :value="form.themeSolidColor"
          name="font_color"
        />
        <UploadImageAndPreview
          name="themeBackGround"
          v-if="form.themeBackgroundPage == 2"
          desc="Dimension 260x1190 px not over 1 MB (Please attach file .jpg only)"
          v-model="form.themeBackGround"
          :value="form.themeBackGround"
          @deleteImage="deleteImage"
          :imagePath="imagePath"
        />
      </div>
    </div>
    <FooterAction routePath="/setting" @submit="saveForm()" />
    <ModalLoading ref="modalLoading" />
  </div>
</template>
<script>
import ModalLoading from "@/components/modal/ModalLoading";
import UploadImageAndPreview from "@/components/inputs/UploadImageAndPreview";
import ColorPicker from "@/components/inputs/InputColorPicker";
export default {
  components: { UploadImageAndPreview, ModalLoading, ColorPicker },
  data() {
    return {
      form: {
        themeLogoLine: "",
        themeLogoLoginPage: "",
        themeLogoHomePage: "",
        themePrimaryColor: "",
        themeLightPrimaryColor: "",
        themeSecondaryColor: "",
        themeDisableColor: "",
        themeBackgroundPage: "1",
        themeSolidColor: "",
        themeBackGround: "",
      },
      imagePath: "",
      listElememt: [
        {
          title: "Logo Liff",
          desc: "Logo from line application dimension 390x96 not over 1 MB (Please attach file .png only)",
          key: "themeLogoLine",
        },
        {
          title: "Logo Front Booth",
          desc: "Logo from login page dimension 800x800 not over 1 MB (Please attach file .png only)",
          key: "themeLogoLoginPage",
        },
        {
          title: "Logo Mainpage Booth",
          desc: "Logo from login page dimension 120x30 not over 1 MB (Please attach file .png only)",
          key: "themeLogoHomePage",
        },
      ],
      isLoading: false,
    };
  },
  mounted() {},

  created() {
    this.getList();
  },
  destroyed() {},
  methods: {
    async getList() {
      const response = await this.$Axios(
        `${process.env.VUE_APP_API}/Setting/theme`
      );
      this.imagePath = response.data.themeLogoDomain;
      this.form = response.data.result;
      this.isLoading = true;
    },
    deleteImage(key) {
      this.form[key] = "";
    },
    async removeValueObj() {
      for (const [key, value] of Object.entries(this.form)) {
        if (value == this.freezeForm[key]) {
          delete this.freezeForm[key];
        } else if (value) {
          this.freezeForm[key] = value;
        }
      }
    },
    async saveForm() {
      this.$refs.modalLoading.show();
      const result = await this.$Axios.post(
        `${process.env.VUE_APP_API}/setting/updatetheme`,
        this.form
      );
      if (result.data.detail == 1) {
        this.$EventBus.$emit("getTheme");
        this.successAlert().then((val) => val && this.$router.push("/setting"));
      } else {
        this.errorAlert(result.data.message);
      }
      this.$refs.modalLoading.hide();
    },
  },
};
</script>
<style>
label.title {
  color: #626266;
  font-size: 16px;
  margin-bottom: 2px;
  /* font-weight: bold; */
}
</style>
